@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/Colors.scss';

.outerWrapper {
  @include for-phone-only {
    padding-bottom: 56px;
  }
  padding: var(--Space-16) 0 var(--Space-80);
}

.contentWrapper {
  @include for-phone-only {
    padding: var(--Space-32) 18px;
    flex-direction: column;
  }
  @include for-tablet-only {
    padding: 30px;
    flex-direction: column;
  }
  @include for-laptop-only {
    padding: 30px 15px var(--Space-40) 28px;
    justify-content: space-between;
  }
  display: flex;
  padding: var(--Space-64) var(--Space-40);
  background-color: var(--GrayLightHover--opaque);
  box-shadow: 0px -15px 15px rgba(0, 0, 0, 0.03),
    0px 15px 30px rgba(0, 0, 0, 0.2), 0px 30px 60px rgba(60, 70, 70, 0.18);
  border-radius: 2px;
}

.textContainer {
  @include for-phone-only {
    width: 99.9%;
  }
  @include for-tablet-only {
    width: 362px;
  }
  @include for-laptop-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 230px;
  }
  width: 450px;
}

.heading {
  @include for-phone-only {
    margin-bottom: 18px;
  }
  @include for-laptop-only {
    width: 270px;
  }
  margin-bottom: var(--Space-24);
}

.subheading {
  @include for-phone-only {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.001em;
    margin-bottom: var(--Space-24);
  }
  @include for-tablet-only {
    font-size: 19px;
    line-height: 24px;
    margin-bottom: var(--Space-32);
  }
  @include for-laptop-only {
    margin-bottom: var(--Space-32);
    font-size: 19px;
    line-height: 24px;
  }
  margin-bottom: var(--Space-48);
  font-size: 27px;
  line-height: 32px;
  letter-spacing: -0.0075em;
}

.ctaAbove {
  @include for-phone-and-tablet {
    display: none;
  }
  width: 160px;
}

.ctaBelow {
  @include for-phone-only {
    width: 100%;
  }
  @include for-laptop-and-up {
    display: none;
  }
  width: 160px;
}

.imageContainer {
  @include for-phone-only {
    width: 100%;
    margin-bottom: var(--Space-32);
  }
  @include for-tablet-only {
    margin-bottom: var(--Space-64);
  }
  @include for-laptop-only {
    margin-top: 35px;
  }
  display: flex;
  justify-content: center;
}
